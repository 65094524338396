<template>
  <v-container class="fill-height not-found">
    <v-row justify="center" align="center">
      <v-col cols="12" md="4" sm="8" class="text-center">
        <h2>404 NOT FOUND</h2>
        <h4>페이지를 찾을 수 없습니다.</h4>

        <v-btn color="primary" outlined @click="moveToHome()">
          <v-icon small class="mr-1">mdi-home-variant</v-icon>
          <span class="mt-1">HOME</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    moveToHome() {
      this.$router.push('/').catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.not-found {
  h2 {
    font-weight: 800 !important;
  }

  h4 {
    letter-spacing: 0.6px;
    margin-left: 4px;
    margin-bottom: 40px;
  }
}
</style>
